// extracted by mini-css-extract-plugin
export var ArtistDescription = "Quik-module--ArtistDescription--ikbLC";
export var ArtistInfos = "Quik-module--ArtistInfos--yxdmo";
export var ButtonWrapper = "Quik-module--ButtonWrapper --KshLn";
export var CardWrapper = "Quik-module--CardWrapper--m1wIP";
export var CarrouselWrapper2 = "Quik-module--CarrouselWrapper2--3qUlG";
export var Citations = "Quik-module--Citations--pArR1";
export var DescriptionWrapper = "Quik-module--DescriptionWrapper--TDgWA";
export var ImageWrapper = "Quik-module--ImageWrapper--22XJE";
export var LinkWrapper = "Quik-module--LinkWrapper--nphCa";
export var MobileProtrait = "Quik-module--MobileProtrait--XmK5E";
export var More = "Quik-module--More--6xLct";
export var MoreButton = "Quik-module--MoreButton--96bkY";
export var NameWrapper = "Quik-module--NameWrapper--ZMVTX";
export var PdpWrapper = "Quik-module--PdpWrapper--2YMKb";
export var PhotosWrapper = "Quik-module--PhotosWrapper--PiCeq";
export var ProfilWrapper = "Quik-module--ProfilWrapper--szwm3";
export var TitleWrapper = "Quik-module--TitleWrapper--+nNUr";
export var Wrapper = "Quik-module--Wrapper--AnPb8";